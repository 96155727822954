html,
body,
div,
span,
object,
iframe,
embed,
main,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
u,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
tbody,
tfoot,
thead,
tr,
th,
td,
caption,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video,
source {
  vertical-align: middle;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  outline: 0;
  border: 0;
  background: transparent;

  padding: 0;
  font-size: 100%;
}

ol,
ul {
  list-style: none;
}

a {
  vertical-align: baseline;
  margin: 0;
  background: transparent;
  padding: 0;
  font-size: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.p-reservebtn {
  margin: 0 auto;
  width: 92%;
}

.p-reservebtn__items {
  position: relative;
  -webkit-transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  cursor: pointer;
  margin-bottom: 13px;
  border: none;
  width: 100%;
  color: #ffffff;
  font-weight: 500;
  font-size: 13px;
  font-family: 'Inter';
  letter-spacing: 0.6px;
  text-align: center;
  background-color: transparent;
}

.p-reservebtn__items:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);
}

.p-reservebtn__items:hover::before {
  -webkit-transform: translateY(10px) translateX(-50%);
  transform: translateY(10px) translateX(-50%);
  -webkit-transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);
}

.p-reservebtn__items:disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.p-reservebtn__items-is-inner {
  display: block;
  position: relative;
  -webkit-transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  border-radius: 60px;
  padding: 8% 17%;
}

.p-reservebtn__items-is-inner-disabled {
  -webkit-transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  background-color: #aaa !important;
  padding: 8% 17%;
  border-radius: 60px;
  display: block;
  position: relative;
}

@media screen and (min-width: 768px) {
  .p-reservebtn {
    margin: 0 auto;
    width: 73%;
    max-width: 333px;
  }
}
