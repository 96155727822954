.p-info__reserve-en {
  margin-bottom: 11%;
  padding: 0;
}

.p-info__reserve-en-checkcont-defo {
  position: relative;
  padding: 0;
  border-radius: 10px;
}

.p-info__reserve-en-checkcont-defo::before {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  border-radius: 10px;
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  content: '';
  pointer-events: none;
  opacity: 0;
}

.p-info-reserve-overseas-home__lead {
  margin-bottom: 11px;
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: 0.8px;
}

.p-info-reserve-overseas-home-note {
  margin-bottom: 30px;
  color: #5c5c5c;
  font-size: 11px;
  line-height: 1.9;
  letter-spacing: 1px;
}

.p-info-reserve-overseas-home-note-item {
  margin-bottom: 9px;
}

.p-info-reserve-overseas-home-note-item a {
  color: #000;
}

.none-jk-home-l-en-wrap {
  position: relative;
  margin: 4% auto;
  width: 92%;
}

.none-jk-home-l-en-wrap::before {
  position: absolute;
  border-radius: 5px;
  background-color: white;
  width: 100%;
  height: 100%;
  pointer-events: none;
  content: '';
}

.pull-down_language {
  z-index: 1;
  margin-right: 10px;
  margin-left: auto;
  padding-top: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.pull-down_language__inner {
  position: relative;
}

.pull-down_language__inner::before {
  position: absolute;
  top: 53%;
  right: 13px;
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
  -webkit-clip-path: polygon(50% 30%, 0% 100%, 100% 100%);
  clip-path: polygon(50% 30%, 0% 100%, 100% 100%);
  background-color: #000;
  width: 9px;
  height: 9px;
  pointer-events: none;
  content: '';
}

.none-jk-home-pull-down_language {
  z-index: 1;
  margin-right: 10px;
  margin-left: auto;
  padding-top: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

@media screen and(min-width: 767px) {
  .p-info__reserve-en-checkcont-defo {
    margin-bottom: 25px;
  }

  .p-info__reserve-en-checkcont-defo::before {
    border-radius: 0;
    width: 91vw;
  }

  .p-info-reserve-overseas-home-note-item {
    margin-bottom: 2px;
  }
}

@media screen and (min-width: 768px) {
  .p-info__reserve-en-checkcont-defo {
    margin-bottom: 26px;
  }

  .p-info-reserve-overseas-home__lead {
    margin-bottom: 19px;
    line-height: 1.8;
    letter-spacing: 1.7px;
  }

  .p-info-reserve-overseas-home-note {
    margin-bottom: 26px;
    font-size: 11px;
    line-height: 1.95;
  }

  .pull-down_language {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 14px;
    padding: 31px 0 0 0;
    font-size: 13px;
  }

  .none-jk-home-l-en-wrap {
    display: block;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 5% auto;
    width: 540px;
    height: 100%;
    min-height: auto;
  }

  .none-jk-home-pull-down_language {
    position: relative;
    padding-top: 25px;
    top: 0;
    right: 0;
    margin-right: 14px;
    margin-left: auto;
    padding: 31px 0 0 0;
    font-size: 13px;
  }
}
