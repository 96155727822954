@charset "UTF-8";

.body {
  color: #000;
  font-weight: 400;
  font-family: 'Noto Sans JP';
}

.view-size {
  padding: 1rem 0rem 0rem 0rem;
  width: 90%;
}

/* new */

.is-load-home {
  opacity: 1;
  -webkit-transition: opacity 0.8s linear;
  transition: opacity 0.8s linear;
  display: block;
  background-color: #f3f3f3;
  color: #000;
  font-weight: 400;
  font-family: 'Noto Sans JP';
}

.p-bg-home {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  pointer-events: none;
}

.p-bg-home__img {
  opacity: 0.8;
  -webkit-filter: blur(20px);
  filter: blur(20px);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.l-wrap-home {
  position: relative;
  margin: 4% auto;
  width: 92%;
}

.l-wrap-home::before {
  position: absolute;
  border-radius: 5px;
  background-color: white;
  width: 100%;
  height: 100%;
  pointer-events: none;
  content: '';
}

.p-hero-home {
  position: relative;
}

.p-hero__img-home {
  overflow: hidden;
}

.p-info-home {
  position: relative;
  padding-bottom: 28%;
  line-height: 1;
}

.p-info-home__inner {
  margin: 0 auto;
  width: 90%;
}

.p-info-home__title {
  margin: 0 0 6.3% 0;
  padding-top: 12%;
  text-align: center;
}

.p-info-home__title span {
  display: block;
  font-weight: 400;
}

.p-info-home__song {
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 1.3;
  letter-spacing: 1.7px;
}

.p-info-home__release {
  color: #5c5c5c;
  font-weight: 500;
  font-size: 13px;
  font-family: 'Inter';
  letter-spacing: 0.5px;
}

.p-info-reserve-home__lead {
  margin-bottom: 11px;
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: 0.8px;
}

.is-home-border {
  margin: 2.5em 0;
}

.p-info-reserve-home-note {
  margin-bottom: 30px;
  color: #5c5c5c;
  font-size: 11px;
  line-height: 1.9;
  letter-spacing: 1px;
}

.p-info__reserve-home-checklist {
  margin-bottom: 11%;
}

.p-info__reserve-home-checklist-items {
  margin-bottom: 16px;
  font-size: 13px;
  line-height: 1.5;
}

/*none-jk */

.none-jk-home-is-load {
  opacity: 1;
  -webkit-transition: opacity 0.8s linear;
  transition: opacity 0.8s linear;
  display: block;
  background-color: #f3f3f3;
  color: #000;
  font-weight: 400;
  font-family: 'Noto Sans JP';
}

.none-jk-home-l-wrap {
  position: relative;
  margin: 4% auto;
  width: 92%;
}

.none-jk-home-l-wrap::before {
  position: absolute;
  border-radius: 5px;
  background-color: white;
  width: 100%;
  height: 100%;
  pointer-events: none;
  content: '';
}

.none-jk-home-p-info {
  position: relative;
  padding-bottom: 28%;
}

.none-jk-home-p-info-inner {
  margin: 0 auto;
  width: 90%;
}

.none-jk-home-p-info-title {
  padding-top: 16%;
  margin: 0 0 6.3% 0;
  text-align: center;
}

.none-jk-home-p-info-title span {
  display: block;
  font-weight: 400;
}

.none-jk-home-p-info-span {
  margin-bottom: 12px;

  font-size: 20px;
  line-height: 1.3;
  letter-spacing: 1.7px;
}

.none-jk-home-p-info-release-date {
  color: #5c5c5c;
  font-weight: 500;
  font-size: 13px;
  font-family: 'Inter';
  letter-spacing: 0.5px;
}

.none-jk-home-p-reservebtn {
  margin: 0 auto;
  width: 92%;
}

@media screen and (min-width: 768px) {
  .p-bg-home {
    z-index: 1;
    width: 45.7%;
    overflow: hidden;
  }

  .p-bg-home__img {
    position: absolute;
    opacity: 0.6;
    -webkit-filter: blur(40px);
    filter: blur(40px);
  }

  .l-wrap-home {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    min-height: 100vh;
  }

  .p-hero-home {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 45.7%;
    height: 100%;
  }

  .p-hero__img-home {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 auto;
    width: 81%;
    max-width: 555px;
    height: 100%;
  }

  .p-info-home {
    margin-left: 45.7%;
    padding-bottom: 5%;
    width: 54.3%;
  }

  .p-info-home__inner {
    margin: 0 auto;
    width: 85%;
    max-width: 519px;
  }

  .p-info-home__title {
    margin: 0 0 5% 0;
    padding-top: 12.4%;
    text-align: left;
  }

  .p-info-home__artist {
    margin-bottom: 7px;
    font-size: 15px;
    line-height: 1.3;
    letter-spacing: 1px;
  }

  .p-info-home__song {
    margin-bottom: 12px;
    font-size: 31px;
  }

  .p-info-home__release {
    font-size: 15px;
    letter-spacing: 1.4px;
  }

  .p-info-reserve-home__lead {
    margin-bottom: 19px;
    line-height: 1.8;
    letter-spacing: 1.7px;
  }

  .p-info-reserve-home-note {
    margin-bottom: 26px;
    font-size: 11px;
    line-height: 1.95;
  }

  .p-info__reserve-home-checklist {
    margin-bottom: 8.5%;
  }

  .p-info__reserve-home-checklist-items {
    margin-bottom: 17px;
    font-size: 12px;
    letter-spacing: 1.4px;
  }

  /* none-jk */

  .none-jk-home-l-wrap {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 5% auto;
    width: 540px;
    height: 100%;
    min-height: auto;
    align-items: center;
    display: flex;
    -webkit-box-align: center;
  }

  .none-jk-home-p-info {
    margin: 0;
    padding-bottom: 20%;
    width: 100%;
  }

  .none-jk-home-p-info-inner {
    margin: 0 auto;
    width: 85%;
    max-width: 519px;
  }

  .none-jk-home-p-info-title {
    margin: 0 0 5% 0;
    padding-top: 14%;
    text-align: center;
  }

  .none-jk-home-p-info-span {
    margin-bottom: 11px;
    font-size: 31px;
    letter-spacing: 3px;
  }

  .none-jk-home-p-info-release-date {
    font-size: 15px;
    letter-spacing: 1.4px;
  }

  .none-jk-home-p-info-lead {
    font-size: 14px;
    line-height: 1.8;
  }

  .none-jk-home-p-reservebtn {
    margin: 0 auto;
    width: 73%;
    max-width: 333px;
  }

  .container {
    position: absolute;
    left: 0;
    width: 100%;

    background-color: #f3f3f3;
  }
}
