.container {
  position: absolute;
  left: 0;
  width: 100%;

  background-color: #f3f3f3;
}

.is-load-instagram {
  opacity: 1;
  -webkit-transition: opacity 0.8s linear;
  transition: opacity 0.8s linear;
  display: block;
  background-color: #f3f3f3;
  color: #000;
  font-weight: 400;
  font-family: 'Noto Sans JP';
}

.p-bg-instagram {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  pointer-events: none;
}

.p-bg-instagram__img {
  opacity: 0.8;
  -webkit-filter: blur(20px);
  filter: blur(20px);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.l-wrap-instagram {
  position: relative;
  margin: 4% auto;
  width: 92%;
}

.l-wrap-instagram::before {
  position: absolute;
  border-radius: 5px;
  background-color: white;
  width: 100%;
  height: 100%;
  pointer-events: none;
  content: '';
}

.p-hero-instagram {
  position: relative;
}

.p-hero__img-instagram {
  overflow: hidden;
}

.p-info-instagram {
  position: relative;
  padding-bottom: 28%;
  line-height: 1;
}

.p-info-instagram__inner {
  margin: 0 auto;
  width: 90%;
}

.p-info-instagram__title {
  margin: 0 0 6.3% 0;
  padding-top: 12%;
  text-align: center;
}

.p-info-instagram__title span {
  display: block;
  font-weight: 400;
}

.p-info-instagram__artist {
  margin-bottom: 5px;
  font-size: 18px;
  letter-spacing: 3.5px;
}

.p-info-instagram__song {
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 1.3;
  letter-spacing: 1.7px;
}

.p-info-instagram__release {
  color: #5c5c5c;
  font-weight: 500;
  font-size: 13px;
  font-family: 'Inter';
  letter-spacing: 0.5px;
}

.p-info-reserve-instagram__lead {
  margin-bottom: 11px;
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: 0.8px;
}

.is-border {
  margin: 2.5em 0;
}

.p-info-instagram-screen {
  text-align: center;
}

.p-info-instagram__reserve-screen-img {
  margin: 0 auto 5%;
  width: 90%;
}

.p-info-instagram__reserve-screen-lead {
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: 0.8px;
}

.p-info-instagram__reserve-cookie {
  margin-top: 26px;
  font-size: 11px;
  line-height: 1.95;
  text-align: center;
}

.p-info-instagram__reserve-cookie a {
  color: #000;
}

/* none-jk */

.none-jk-instagram-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background: center;
}

.none-jk-instagram-is-load {
  opacity: 1;
  -webkit-transition: opacity 0.8s linear;
  transition: opacity 0.8s linear;
  display: block;
  background-color: #f3f3f3;
  color: #000;
  font-weight: 400;
  font-family: 'Noto Sans JP';
}

.none-jk-instagram-l-wrap {
  position: relative;
  margin: 4% auto;
  width: 92%;
}

.none-jk-instagram-l-wrap::before {
  position: absolute;
  border-radius: 5px;
  background-color: white;
  width: 100%;
  height: 100%;
  pointer-events: none;
  content: '';
}

.none-jk-instagram-p-info {
  position: relative;
  padding-bottom: 28%;
}

.none-jk-instagram-p-info-inner {
  margin: 0 auto;
  width: 90%;
}

.none-jk-instagram-p-info-title {
  padding-top: 16%;
  margin: 0 0 6.3% 0;
  text-align: center;
}

.none-jk-instagram-p-info-title span {
  display: block;
  font-weight: 400;
}

.none-jk-instagram-p-info-span {
  margin-bottom: 12px;

  font-size: 20px;
  line-height: 1.3;
  letter-spacing: 1.7px;
}

.none-jk-instagram-p-info-release-date {
  color: #5c5c5c;
  font-weight: 500;
  font-size: 13px;
  font-family: 'Inter';
  letter-spacing: 0.5px;
}

.none-jk-instagram-p-info-lead {
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: 0.5px;
}

@media screen and (min-width: 768px) {
  .p-bg-instagram {
    z-index: 1;
    width: 45.7%;
    overflow: hidden;
  }

  .p-bg-instagram__img {
    position: absolute;
    opacity: 0.6;
    -webkit-filter: blur(40px);
    filter: blur(40px);
  }

  .l-wrap-instagram {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    min-height: 100vh;
  }

  .p-hero-instagram {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 45.7%;
    height: 100%;
  }

  .p-hero__img-instagram {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 auto;
    width: 81%;
    max-width: 555px;
    height: 100%;
  }

  .p-info-instagram {
    margin-left: 45.7%;
    padding-bottom: 5%;
    width: 54.3%;
  }

  .p-info-instagram__inner {
    margin: 0 auto;
    width: 85%;
    max-width: 519px;
  }

  .p-info-instagram__title {
    margin: 0 0 5% 0;
    padding-top: 12.4%;
    text-align: left;
  }

  .p-info-instagram__artist {
    margin-bottom: 7px;
    font-size: 15px;
    line-height: 1.3;
    letter-spacing: 1px;
  }

  .p-info-instagram__song {
    margin-bottom: 12px;
    font-size: 31px;
  }

  .p-info-instagram__release {
    font-size: 15px;
    letter-spacing: 1.4px;
  }

  .p-info-reserve-instagram__lead {
    margin-bottom: 19px;
    line-height: 1.8;
    letter-spacing: 1.7px;
  }

  .p-info-instagram__reserve-screen-img {
    max-width: 366px;
  }

  .p-info-instagram__reserve-screen-lead {
    line-height: 1.8;
    letter-spacing: 1.7px;
  }

  /* none-jk */

  .none-jk-instagram-l-wrap {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 5% auto;
    width: 540px;
    height: 100%;
    min-height: auto;
    align-items: center;
    display: flex;
    -webkit-box-align: center;
  }

  .none-jk-instagram-p-info {
    margin: 0;
    padding-bottom: 20%;
    width: 100%;
  }

  .none-jk-instagram-p-info-inner {
    margin: 0 auto;
    width: 85%;
    max-width: 519px;
  }

  .none-jk-instagram-p-info-title {
    margin: 0 0 5% 0;
    padding-top: 14%;
    text-align: center;
  }

  .none-jk-instagram-p-info-span {
    margin-bottom: 11px;
    font-size: 31px;
    letter-spacing: 3px;
  }

  .none-jk-instagram-p-info-release-date {
    font-size: 15px;
    letter-spacing: 1.4px;
  }

  .none-jk-instagram-p-info-lead {
    font-size: 14px;
    line-height: 1.8;
  }
}
