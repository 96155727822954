.my-node-enter {
  opacity: 0;
  transform: scale(1.1);
}

.my-node-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: transform 4000ms cubic-bezier(0.33, 1, 0.68, 1);
}

.my-node-enter-done {
  opacity: 1;
  transform: scale(1);
}

.window-size {
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .window-size {
    width: 300px;
    height: 300px;

    overflow: hidden;
  }
}

@media screen and (min-width: 820px) {
  .window-size {
    width: 350px;
    height: 350px;

    overflow: hidden;
  }
}

@media screen and (min-width: 912px) {
  .window-size {
    width: 350px;
    height: 350px;

    overflow: hidden;
  }
}

@media screen and (min-width: 1280px) {
  .window-size {
    width: 500px;
    height: 500px;
    text-align: start;
    overflow: hidden;
  }

  .my-node-enter {
    opacity: 0;
    transform: scale(1.1);
  }

  .my-node-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: transform 3500ms cubic-bezier(0.33, 1, 0.68, 1);
  }

  .my-node-enter-done {
    opacity: 1;
    transform: scale(1);
  }
}

@media screen and (min-width: 1024px) {
  .window-size {
    width: 400px;
    height: 400px;

    overflow: hidden;
  }

  .my-node-enter {
    opacity: 0;
    transform: scale(1.1);
  }

  .my-node-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: transform 4000ms cubic-bezier(0.33, 1, 0.68, 1);
  }

  .my-node-enter-done {
    opacity: 1;
    transform: scale(1);
  }
}

@media screen and (min-width: 1526px) {
  .window-size {
    width: 500px;
    height: 500px;

    overflow: hidden;
  }

  .my-node-enter {
    opacity: 0;
    transform: scale(1.1);
  }

  .my-node-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: transform 4000ms cubic-bezier(0.33, 1, 0.68, 1);
  }

  .my-node-enter-done {
    opacity: 1;
    transform: scale(1);
  }
}
